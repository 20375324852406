import React from "react";
import * as Icon from "react-bootstrap-icons";
import * as types from "../../constants/ActionTypes";
import vector from "../../assets/setup-icon/Vector.png";
import {
  Form,
  InputGroup,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {addPartner, baseTypeParent, fetchAddPartnerData, fetchDesignation} from "../../redux/actions/setupActions"
import Multiselect from "multiselect-react-dropdown";
import "./setup.css";
import { useState, useRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { selectDesignation, saveAllPartnerData } from "../../redux/actions/setupActions";
import Toast from "../shared/Toast";
import Loading from "../Loading";

const EditPartner = (props) => {  
  const dispatch = useDispatch();
  const talic_active = useSelector((state) => state.assetLibrary.talic_active);
  // const editData = useSelector((state) => state?.setupScreen.edit_partner);
  const addData = useSelector((state) => state?.setupScreen.add_partner);
  const loading = useSelector((state) => state?.setupScreen.loading);
  const baseTypeParentData = useSelector((state) => state?.setupScreen.base_type_parent);
  const [editDataCopy, setEditDataCopy] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [digicardType, setDigicardType] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [designation, setDesignation] = useState("");
  const [uinNum, setUinNum] = useState("");
  const [uidNum, setUidNum] = useState("");
  const [cluster, setCluster] = useState("");
  const [contactLimit, setContactLimit] = useState("");
  const [branchManager, setBranchManager] = useState("");
  const [zonalManager, setZonalManager] = useState("");
  const [regionalManager, setRegionalManager] = useState("");
  const [agentManager, setAgentManager] = useState("");
  const [pageName, setPageName] = useState("Personal");
  const [counter, setCounter] = useState(1);
  const [userTypeId, setUserTypeId] = useState("");
  const [accessTypeId, setAccessTypeId] = useState("");
  const [buttonIds, setButtonIds] = useState([]);
  const [buttonName, setButtonName] = useState([]);
  const [buttonUrl, setButtonUrl] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [cityId, setCityId] = useState("");
  const [branchId, setBranchId] = useState("");
  const [designationDropId, setDesignationDropId] = useState("");
  const [baseTypeParentId,setBaseTypeParentId] = useState([]);
  const [phoneArr, setPhoneArr] = useState([]);
  const [inputValues, setInputValues] = useState({0:""});
  const [addNewValues, setAddNewValues] = useState([{
    title: "",
    url: "",
  }]);
  const [baseTypeOption, setBaseTypeOption] = useState([]);
  
  const useQuery= () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
 
  useEffect(() => {
    dispatch(fetchAddPartnerData(props.authToken))
  }, [])

  

  useEffect(() => {
    const button_ids = addNewValues.map((item) => { return item.id ? item.id : "" });
    setButtonIds(button_ids);
    const button_name = addNewValues.map((item) => { return item.title });
    setButtonName(button_name);
    const button_url = addNewValues.map((item) => { return item.url });
    setButtonUrl(button_url);
  }, [addNewValues]);
  
  useEffect(() => {
    const phone_arr = Object.values(inputValues);
    setPhoneArr(phone_arr);
  }, [inputValues])
  
  const handleAddPhone = (e) => {
    if(counter < 3){
      setCounter(counter + 1);
    }
  };
  const handleAdd = (e) => {
    setAddNewValues([...addNewValues, { title: "", url: "" }]);

  };
  const deleteItem = (index) => {
    const values = [...addNewValues];
    values.splice(index, 1);
    setAddNewValues(values);
  };
  const onchangeAddInput = (event, index) => {

    const values = [...addNewValues];
    if (event.target.name === "title") {
      values[index].title = event.target.value;
    } else {
      values[index].url = event.target.value;
    }
    setAddNewValues(values); 
  };
 /////////////////////////// Designation And heirarchy Start //////////////////////////////////////
  
  const designation_list = useSelector((state) => state.setupScreen.designation_list);
  const [designationOrder, setDesignationOrder] = useState(null);  
  const [distributedData, setDistributedData] = useState(null);
  let designation_list_support = useSelector((state) => state.setupScreen.designation_list_support);
  const partner_id_h = useSelector((state) => state.setupScreen.partner_id);
  
  useEffect(()=>{
    return () => {
      dispatch({ type: types.FETCH_DESIGNATION_LIST_SUPPORT, payload: [] });
      dispatch({ type: types.EDIT_PARTNER, payload: null });
    }
  },[])
  
  const toggleToDesignation = () => {
      setPageName("Designation")
  }
  
  const proceedDetails = () => {
      let heirarchy = designation_list_support.map((key) => {
        return {
          designation_id: key.designation_id,
          users: key.selected_value.map((val) => {
            return val.user_id
          })
        }
      })
      dispatch(saveAllPartnerData(props.authToken, partner_id_h, heirarchy));
  }
  
  useEffect(() => {
    if (designation_list_support) {
      let middleIndex, leftData, rightData, combineData;
      let initialData = [...designation_list_support];
      middleIndex = Math.ceil(initialData.length/2);
      leftData = initialData.splice(0, middleIndex);
      rightData = initialData.splice(-middleIndex);
      combineData = [leftData, rightData]
      setDistributedData(combineData); 
    }
  }, [designation_list_support])
  
  const onRemoveDesignationData = async (selectedList, removedItem, des_id, order) => {
    let selectedId = selectedList.map((key) => { return key.user_id })
    let preserveObject = designation_list_support[order];
    preserveObject.selected_value = selectedList;
    designation_list_support[order] = preserveObject;
    designation_list_support.splice(order+1);
    await dispatch(selectDesignation(props.authToken, "remove", order, designation_list_support, des_id, selectedId, partner_id_h));
  }
  
  const onSelectDesignationData = async (selectedList, selectedItem, des_id, order) => {
    let selectedId = selectedList.map((key) => { return key.user_id })
    let preserveObject = designation_list_support[order];
    preserveObject.selected_value = selectedList;
    designation_list_support[order] = preserveObject;
    designation_list_support.splice(order+1);
    await dispatch(selectDesignation(props.authToken, "add", order, designation_list_support, des_id, selectedId, partner_id_h));
  }
  /////////////////////////// Designation And heirarchy End //////////////////////////////////////

  const onchangeInput = (e, index) => {
    const abc = {};
    abc[index] = e.target.value;
    setInputValues({ ...inputValues, ...abc });
  };
 
  const onSelectCategory = (selectedList, selectedItem) => {
    // setCategoryValues(selectedList);
    let categoryID = selectedList.map((item) => { return item.id});
    setCategoryId(categoryID);
  };
  const onRemoveCategory = (selectedList, removedItem) => {
    // setCategoryValues(selectedList);
    let categoryID = selectedList.map((item) => { return item.id});
    setCategoryId(categoryID);
  };
  const onSelectRegion = (selectedList, selectedItem) => {
    // setRegionValues(selectedList);
    let regionID = selectedList.map((item) => { return item.id});
    setRegionId(regionID)
  };
  // console.log(regionId,"regionID")
  const onRemoveRegion = (selectedList, removedItem) => {
    // setRegionValues(selectedList);
    let regionID = selectedList.map((item) => { return item.id});
    setRegionId(regionID?.length > 0 ? regionID : "")
  };
  // console.log(regionId,"regionID")
  const onSelectCity = (selectedList, selectedItem) => {
    // setCityValues(selectedList);
    let cityID = selectedList.map((item) => { return item.city_id});
    setCityId(cityID)
  };
  const onRemoveCity = (selectedList, removedItem) => {
    // setCityValues(selectedList);
    let cityID = selectedList.map((item) => { return item.city_id});
    setCityId(cityID?.length > 0 ? cityID : "")
  };
  const onSelectBranch = (selectedList, selectedItem) => {
    // setBranchValues(selectedList);
    let branchID = selectedList.map((item) => { return item.branch_id});
    setBranchId(branchID)
    // setpayloadBranchID({ xyz:branchID})
  };
  const onRemoveBranch = (selectedList, removedItem) => {
    // setBranchValues(selectedList);
    let branchID = selectedList.map((item) => { return item.branch_id});
    setBranchId(branchID?.length > 0 ? branchID : "")
  };
  const onSelectUserType = (selectedList, selectedItem) => {
    // setUserTypeValues(selectedList);
    let userTypeID = selectedList.map((item) => { return item.code});
    // console.log(userTypeID,"userTypeID")
    setUserTypeId(userTypeID);
  };
  const onRemoveUserType = (selectedList, removedItem) => {
    // setUserTypeValues(selectedList);
    let userTypeID = selectedList.map((item) => { return item.code});
    setUserTypeId(userTypeID?.length > 0 ? userTypeID : "")
  };
  const onSelectAccessType = (selectedList, selectedItem) => {
    // setAccessTypeValues(selectedList);
    let accessTypeID = selectedList.map((item) => { return item.code});
    setAccessTypeId(accessTypeID);
  };
  const onRemoveAccessType = (selectedList, removedItem) => {
    // setAccessTypeValues(selectedList);
    let accessTypeID = selectedList.map((item) => { return item.code});
    setAccessTypeId(accessTypeID?.length > 0 ? accessTypeID : "")
  };
  const onSelectDesignation = (selectedList, selectedItem) => {
    setDesignationOrder(selectedItem.order)
    // setDesignationDropValues(selectedList);
    let designationDropID = selectedList.map((item) => { return item.id});
    setDesignationDropId(designationDropID);
  };
  const onRemoveDesignation = (selectedList, removedItem) => {
    setDesignationOrder(null);
    // setDesignationDropValues(selectedList);
    let designationDropID = selectedList.map((item) => { return item.id});
    setDesignationDropId(designationDropID?.length > 0 ? designationDropID : "")
  };

  let payloadObject = {
    action: "add",
    UID: uidNum,
    EUIN: uinNum,
    first_name: firstName,
    last_name: lastName,
    category: categoryId ,
    email: email,
    phone: phoneArr,
    company:companyName ,
    company_website: companyWebsite,
    designation:designation,
    branch_manager:branchManager,
    zonal_manager: zonalManager,
    regional_manager: regionalManager,
    agent_manager:agentManager,
    button_name: buttonName,
    digicard: digicardType,
    url: buttonUrl,
    region_id: regionId?.length > 0 ? regionId.toString() : "",
    city_id: cityId?.length > 0 ? cityId.toString() : "",
    branch_id: branchId?.length > 0 ? branchId.toString() : "",
    cluster: cluster,
    user_type:userTypeId?.length > 0 ? userTypeId.toString() : "",
    access_type: accessTypeId?.length > 0 ? accessTypeId.toString() : "",
    contact_limit: contactLimit,
    level_id:addData?.designation_hierarchy == 1 && designationDropId ? designationDropId.toString() : "",
    base_type_parent: baseTypeParentId ? baseTypeParentId.toString() : "",
  };

  // console.log(payloadObject,"AddpayloadObject")

  useEffect(() => {
    if(accessTypeId.toString() == "B"){
      dispatch(baseTypeParent(props.authToken,"",""))
    }
  },[accessTypeId])

  // console.log(accessTypeId.toString(),"accessTypeId")

  const onSearchBaseTypeParent = (searchInput) =>{
    dispatch(baseTypeParent(props.authToken,searchInput?.length > 0 ? searchInput : "",""))
    // console.log(searchInput.length,"searchInput")
  }

  useEffect(() => {
    if (baseTypeParentData && baseTypeParentData?.length > 0) {
      setBaseTypeOption(baseTypeParentData);
     }
    //   else {
    //    setOptions(Data);
    //  }
  },[baseTypeParentData])

  const onSelectBaseTypeParentData = (selectedList, selectedItem) => {
    // setDesignationDropValues(selectedList);
    // console.log(selectedList,"selectedList")
    let baseTypeParentID = selectedList.map((item) => { return item.user_id});
    setBaseTypeParentId(baseTypeParentID);
  };

  const onRemoveBaseTypeParentData = (selectedList, removedItem) => {
    // setDesignationDropValues(selectedList);
    let baseTypeParentID = selectedList.map((item) => { return item.user_id});
    setBaseTypeParentId(baseTypeParentID);
  };


  const handleSave = async () =>{

    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if(firstName === ""){
        Toast("Please Enter First Name", "E");
      }else if(lastName === ""){
        Toast("Please Enter LastName", "E");
      }else if(phoneArr[0] === ""){
        Toast("Please Enter Phone Number", "E");
      }else if(uidNum === ""){
        Toast("Please Enter UID Number", "E");
      }else if(payloadObject.user_type === ""){
        Toast("Please Select User Type", "E");
      }else if(payloadObject.access_type === ""){
        Toast("Please Select Access Type", "E");
      }else if(email){
        if((email === "" || regex.test(email) === false) && talic_active !== "tal"){ 
          Toast("Please Enter Valid Email", "E");
        }
        else if ((email !== "" && regex.test(email) === false) && talic_active === "tal") {
          Toast("Please Enter Valid Email", "E");
        }
        else{
          await dispatch(addPartner(props.authToken,payloadObject,{heirarchy_check: designationOrder !== 1 && designationOrder !== null ? "pass":"cross"}))
          if (designationOrder !== 1 && designationOrder !== null && talic_active === "tal") {
            toggleToDesignation();
          } else {
            window.history.back();
          }
        }
      }else{
        await dispatch(addPartner(props.authToken,payloadObject,{heirarchy_check: designationOrder !== 1 && designationOrder !== null ? "pass":"cross"}))
        if (designationOrder !== 1 && designationOrder !== null && talic_active === "tal") {
          toggleToDesignation();
        } else {
          window.history.back();
        }
      }
  }

  return (
    <div className="edit-partner">
      <span
      className="cursor-pointer"
        onClick={() => {
          dispatch({ type: types.ADD_PARTNER, payload: null });
          window.history.back();
        }}
      >
        <img className="rotate-left " src={vector} alt="icon" />
        Back
      </span>
      <div className="d-flex m20">
        <h2 className="main-title my-auto me-5">{props.innerTitle}</h2>
        {/* <span className="urn my-auto ms-5">
          Uid no: <b>{editData?.urn_no}</b>
        </span> */}
      </div>
      <Row className="setup-category">
        <Col className="p-0">
          <div>
            <ul>
              <li
                className={
                  pageName === "Personal" ? "tabactive" : ""
                }
                onClick={() => {
                  setPageName("Personal");
                //   dispatch(editPartner(props.authToken,query.get("id")))
                }}
              >
                <span>Personal Info</span>
              </li>
               {addData?.designation_hierarchy == 1 && designationOrder !== 1 && designationOrder !== null && <li
                className={ pageName === "Designation" ? "tabactive" : "" }
                // onClick={toggleToDesignation}
              >
                <span>Levels & Hierarchy</span>
              </li>}
              
            </ul>
          </div>
        </Col>
        {pageName === "Designation" && (
          <Col>
            <div className="right-buttons w100">
              <Button
                className="common-setup-button ms-auto"
                onClick={proceedDetails}
                variant="primary"
              >
                Proceed
              </Button>
            </div>
          </Col>
        )}

        <hr></hr>
      </Row>
      {loading && <Loading />}

      {pageName === "Personal" && (
        <>
          {" "}
          <div className="row">
            <div className="col-6">
              <div>
                <p>personal Information</p>
                <Form>
                <Form.Control
                    className="add-name my-3"
                    value={uidNum}
                    type="text"
                    placeholder="UID Number *"
                    onChange={(e) => {
                      setUidNum(e.target.value);
                    }}
                  />
                  {
                    addData?.buy_now_link == 1 &&
                  <Form.Control
                    className="add-name my-3"
                    value={uinNum}
                    type="text"
                    placeholder="UIN Number"
                    onChange={(e) => {
                      setUinNum(e.target.value);
                    }}
                  />
                  }
                  <Form.Control
                    className="add-name my-3"
                    value={firstName}
                    type="text"
                    placeholder="First Name *"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  <Form.Control
                    className="add-name my-3"
                    value={lastName}
                    type="text"
                    placeholder="Last Name *"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />

                  <Multiselect
                    className="drop-down mb-3"
                    options={addData?.category_array}
                    showArrow
                    // selectedValues={categoryValues}
                    onSelect={onSelectCategory}
                    showCheckbox={true}
                    onRemove={onRemoveCategory}
                    displayValue="partner_category"
                    placeholder="Category"
                  />
                  
                  <Form.Control
                    className="add-name my-3"
                    value={digicardType}
                    type="text"
                    placeholder="Digicard Type"
                    onChange={(e) => {
                      setDigicardType(e.target.value);
                    }}
                  />
                  
                </Form>
              </div>
              <hr></hr>

              <div>
                <p>Contact</p>
                <Form>
                  <Form.Control
                    className="add-name my-3"
                    value={email}
                    type="text"
                    placeholder={talic_active !== "tal" ? "Email *":"Email"}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                
                  {Array.from(Array(counter)).map((c, index) => {
                    return (
                      <Form.Control
                        key={index}
                        value={inputValues[index]}
                        className="add-name my-3"
                        type="number"
                        placeholder="Phone *"
                        onChange={(e) => {
                          onchangeInput(e, index);
                        }}
                      />
                    );
                  })}
                  <Button
                    variant="primary"
                    className="save-button mb-1 add-phone"
                    size="lg"
                    onClick={handleAddPhone}
                  >
                    <span className="fs-4 me-2 my-auto">+</span>Add Phone
                  </Button>
                </Form>
                <hr></hr>
              </div>
              <div>
                <p>Company </p>
                <Form>
                  <Form.Control
                    className="add-name my-3"
                    value={companyName}
                    type="text"
                    placeholder="Company Name"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  />
                  {
                    addData?.designation_hierarchy == 1 &&
                    <Multiselect
                    className="drop-down mb-3"
                    options={addData?.designation_array}
                    // selectedValues={designationDropValues}
                    onSelect={onSelectDesignation}
                    onRemove={onRemoveDesignation}
                    singleSelect
                    displayValue="designation"
                    placeholder="Level"
                  />
                }
                <Form.Control
                 className="add-name my-3"
                 value={designation}
                 type="text"
                 placeholder="Designation"
                 onChange={(e) => {
                   setDesignation(e.target.value);
                 }}
               />
                  {addData?.designation_hierarchy == 0 &&
                  <>
                  <Form.Control
                    className="add-name my-3"
                    value={branchManager}
                    type="text"
                    placeholder="Branch Manager"
                    onChange={(e) => {
                      setBranchManager(e.target.value);
                    }}
                  />
                  <Form.Control
                    className="add-name my-3"
                    value={zonalManager}
                    type="text"
                    placeholder="Zonal Manager"
                    onChange={(e) => {
                      setZonalManager(e.target.value);
                    }}
                  />
                  <Form.Control
                    className="add-name my-3"
                    value={regionalManager}
                    type="text"
                    placeholder="Regional Manager"
                    onChange={(e) => {
                      setRegionalManager(e.target.value);
                    }}
                  />
                  <Form.Control
                    className="add-name my-3"
                    value={agentManager}
                    type="text"
                    placeholder="Agent Manager"
                    onChange={(e) => {
                      setAgentManager(e.target.value);
                    }}
                  />
                  </>
                  }
                  <Form.Control
                    className="add-name my-3"
                    value={companyWebsite}
                    type="text"
                    placeholder="Website"
                    onChange={(e) => {
                      setCompanyWebsite(e.target.value);
                    }}
                  />
                </Form>
                <hr></hr>
              </div>
              <div>
                <p>Buy online</p>
                {addNewValues.map((addNewValue, index) => {
                  return (
                    <InputGroup className="mb-3" key={index}>
                      <Form.Control
                        id="input-group-dropdown-1"
                        className="w20"
                        value={addNewValue.title}
                        onChange={(e) => {
                          onchangeAddInput(e, index);
                        }}
                        name="title"
                        placeholder="Button Name"
                      />
                      <Form.Control
                        className=" w60"
                        aria-label="Text input with dropdown button"
                        value={addNewValue.url}
                        onChange={(e) => {
                          onchangeAddInput(e, index);
                        }}
                        name="url"
                        placeholder="Button Url"

                      />
                      {index > 0 && (
                        <InputGroup.Text
                          className="cursor-pointer delete-add"
                        >
                          <Icon.TrashFill onClick={() => deleteItem(index)} color="rgba(33, 33, 33, 0.3)" />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  );
                })}
                <Button
                  variant="primary"
                  className="save-button mb-1 add-phone"
                  size="lg"
                  onClick={handleAdd}
                >
                  <span className="fs-4 me-2 my-auto">+</span>Add
                </Button>
              </div>
              <hr></hr>
            </div>
            <div className="col-6">
              <div>
                <p>Area Information</p>
                <Multiselect
                  className="drop-down mb-3"
                  options={addData?.region_array}
                //   selectedValues={regionValues}
                  onSelect={onSelectRegion}
                  onRemove={onRemoveRegion}
                  singleSelect
                  displayValue="name"
                  placeholder="Region"
                />
                <Multiselect
                  className="drop-down mb-3"
                  options={addData?.city_array}
                //   selectedValues={cityValues}
                  onSelect={onSelectCity}
                  onRemove={onRemoveCity}
                  singleSelect
                  displayValue="city_name"
                  placeholder="City"
                />
                <Multiselect
                  className="drop-down mb-3"
                  options={addData?.branch_array}
                //   selectedValues={branchValues}
                  onSelect={onSelectBranch}
                  onRemove={onRemoveBranch}
                  singleSelect
                  displayValue="branch_name"
                  placeholder="Branch"
                />
                <Form.Control
                  className="add-name my-3"
                  value={cluster}
                  type="text"
                  placeholder="Cluster"
                  onChange={(e) => {
                    setCluster(e.target.value);
                  }}
                />
                <hr></hr>
              </div>
              <div>
                <p>User Information</p>
                <Multiselect
                  className="drop-down mb-3"
                  options={addData?.users_array}
                //   selectedValues={userTypeValues}
                  onSelect={onSelectUserType}
                  onRemove={onRemoveUserType}
                  singleSelect
                  displayValue="title"
                  placeholder="User Type *"
                />
                <Multiselect
                  className="drop-down mb-3"
                  options={addData?.access_type_array}
                //   selectedValues={accessTypeValues}
                  onSelect={onSelectAccessType}
                  onRemove={onRemoveAccessType}
                  singleSelect
                  displayValue="title"
                  placeholder="Access Type *"
                />
                {baseTypeParentData && baseTypeParentData?.length > 0 && accessTypeId == "B" && 
                <Multiselect
                  className="drop-down mb-3"
                  options={baseTypeParentData}
                //   selectedValues={editData?.selected_base_type_parent}
                  onSelect={onSelectBaseTypeParentData}
                  onRemove={onRemoveBaseTypeParentData}
                  onSearch={onSearchBaseTypeParent}
                  displayValue="text"
                  placeholder="Base Type Parent"
                  selectionLimit={1}
                />
                }
                <hr></hr>
              </div>
              <div>
                <p>Contact Limit</p>
                <InputGroup className="mb-3 w-100">
                  <Form.Control
                    id="input-group-dropdown-1"
                    className="w30"
                    value={contactLimit}
                    onChange={(e) => {
                      setContactLimit(e.target.value);
                    }}

                  // aria-label="Global contact limit: 1000"
                  />
                  <Form.Control
                    className="bg-input-dark w60"
                    placeholder="Global contact limit: 1000"
                    disabled
                  // aria-label="Global contact limit: 1000"
                  />
                </InputGroup>
                <hr></hr>
                <Button variant="primary" className="save-button" size="lg" onClick={handleSave}>
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
        
      {/* Designation && Heirarchy By Harish */}
      { pageName !== "Personal" && designationOrder !== 1 && designationOrder !== null &&
        <div className="row heirarchy-bordering">
          {distributedData?.length > 0 && distributedData.map((item) => {
            return (
              <div className="col-6 px-3">
                {item?.length > 0 &&
                  item.map((innerItem) => {
                      return (
                        <div className="row mb-3 ">
                          <span className="order-count col-1">{innerItem.order}.</span>
                          <span className="multiSelectTag my-auto col-2">{innerItem.designation_name}</span>
                          <div className="col-9">
                            <Multiselect
                              singleSelect
                              className="drop-down my-auto "
                              options={innerItem.related_users}
                              selectedValues={innerItem.selected_value}
                              onSelect={(selectedItem, selectedList) => { if (selectedItem.length > 0) { onSelectDesignationData(selectedItem, selectedList, innerItem.designation_id, innerItem.order - 1); } }}
                              onRemove={(selectedItem, removedItem) => { if (selectedItem.length > 0) { onRemoveDesignationData(selectedItem, removedItem, innerItem.designation_id, innerItem.order - 1); } }}
                              displayValue={"user_name"}
                              placeholder="Select Heirarchy"
                              showCheckbox
                            />
                          </div>
                        </div>
                      )
                  })}
              </div> 
            );
          })}
        </div>
      }
      {/* Designation && Heirarchy By Harish */}
    </div>
  );
};

export default EditPartner;
